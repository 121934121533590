import React from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import CONSTANTS from "../../../constants";
import FormStyles from "./ContactUsForm.module.scss";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

function ContactUsForm({ submitted, page, section, helpText, url }) {
  const handlesubmit = (values, resetForm) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        openSuccessToast();
        resetForm();
        submitted();
      })
      .catch(() => openFailureToast);
  };

  function openSuccessToast() {
    toast.success(CONSTANTS.CONTACT_US_SUCCESS_MESSAGE, {
      position: CONSTANTS.CONTACT_US_SUCCESS_MESSAGE_POSITION,
      autoClose: CONSTANTS.CONTACT_US_SUCCESS_MESSAGE_AUTO_CLOSE_DURATION,
    });
  }

  function openFailureToast() {
    toast.error(CONSTANTS.CONTACT_US_FAILURE_MESSAGE, {
      position: CONSTANTS.CONTACT_US_SUCCESS_MESSAGE_POSITION,
      autoClose: CONSTANTS.CONTACT_US_SUCCESS_MESSAGE_AUTO_CLOSE_DURATION,
    });
  }

  return (
    <div>
      <Formik
        initialValues={{ name: "", email: "", message: "", page, section, url }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.message) {
            errors.message = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            setSubmitting(false);
            handlesubmit(values, resetForm);
          }, 0);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form name="contact" onSubmit={handleSubmit} data-netlify="true">
            <div className={FormStyles.infoFields}>
              <div className={FormStyles.inputWrapper}>
                <input
                  type="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Name*"
                />
                {errors.name && touched.name && <span className={FormStyles.errorMessage}>{errors.name}</span>}
                
              </div>
              <div className={FormStyles.inputWrapper}>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="Email*"
                />
                {errors.email && touched.email && <span className={FormStyles.errorMessage}>{errors.email}</span>}
                
              </div>
            </div>
            <div className={FormStyles.reasonField}>
              <label htmlFor="help">{helpText}</label>
              <textarea
                type="text"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                rows="4"
                id="help"
              />
              {errors.message && touched.message && <span className={FormStyles.textError}>{errors.message}</span>}
            </div>
            <input type="hidden" name="page" value={page} />
            <input type="hidden" name="url" value={url} />
            <input type="hidden" name="section" value={section} />
            <div className={FormStyles.modalFooter}>
              <button id={`${page.toLowerCase()}-contact-submit`} type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

ContactUsForm.defaultProps = {
  helpText: 'How can we help?*',
}

export default ContactUsForm;
